'use client'

import { useGraphQL } from 'hooks/useGraphQL/useGraphQL'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useSession } from 'session/src/hooks/useSession'
import useSWR, {
	type KeyedMutator,
	type SWRConfiguration,
	useSWRConfig,
} from 'swr'
import { url as urlUtil } from 'utils/url'

import {
	DYNATRACE_ERROR_TYPE,
	SHOPPING_CART_SERVICES_ENDPOINT,
} from '../constants/constants'
import { type GraphQlError, useHandleErrors } from './useHandleErrors'

interface FetcherOptionsType<TVariables> {
	url?: string
	query?: string
	variables?: TVariables
	headers?: Record<string, string>
}

export type FetcherPropsType<TVariables> =
	| string
	| FetcherOptionsType<TVariables>

export interface GlobalMutate<TVariables> {
	(key: FetcherOptionsType<TVariables>, params: object, options: object): void
}

type FetcherResponse<TData, TVariables> = {
	fetcher: <FData, FVariables>(
		data?: FetcherPropsType<FVariables>
	) => Promise<FData>
	data?: TData
	error: Error[] | null
	mutate: KeyedMutator<TData>
	globalMutate: GlobalMutate<TVariables>
	isLoading: boolean
}

export interface BagVariablesType {
	orderBy: {
		createdAt: 'DESC' | 'ASC'
	}
}

export interface BagHeadersType {
	'x-disable-legacy': string
}

export const bagVariables: BagVariablesType = {
	orderBy: { createdAt: 'DESC' },
}

export const bagHeaders: BagHeadersType = {
	'x-disable-legacy': 'true',
}

export const fetchUrl = urlUtil(
	`${process.env.NEXT_PUBLIC_SITE_BASE_URL}${SHOPPING_CART_SERVICES_ENDPOINT}`
)

const isGraphQLResponseError = (error: unknown): error is Error[] =>
	Array.isArray(error) && error.length > 0 && error[0].message !== undefined

function parseFetcherData<FVariables>(
	fetchData?: FetcherPropsType<FVariables>
): {
	url: string
	fetcherOptions: FetcherOptionsType<FVariables>
} {
	let url = fetchUrl
	let fetcherOptions: FetcherOptionsType<FVariables> = {}

	if (fetchData) {
		if (typeof fetchData === 'string') {
			url = fetchData
		} else if (Array.isArray(fetchData)) {
			fetcherOptions = {
				query: fetchData[0],
				variables: fetchData[1],
			}
		} else {
			fetcherOptions = fetchData
			url = fetcherOptions.url ? fetcherOptions.url : fetchUrl
		}
	}
	return { url, fetcherOptions }
}

const errorMapping: Record<string, DYNATRACE_ERROR_TYPE> = {
	AddItem: DYNATRACE_ERROR_TYPE.ADD,
	ClearItem: DYNATRACE_ERROR_TYPE.CLEAR,
	RemoveItem: DYNATRACE_ERROR_TYPE.REMOVE,
	MergeWithGuest: DYNATRACE_ERROR_TYPE.MERGE,
	FindTotalItemsByUser: DYNATRACE_ERROR_TYPE.QUERY_TOTAL_ITEMS,
	FindByUser: DYNATRACE_ERROR_TYPE.QUERY_FIND_BY_USER,
}

const getErrorHandler = (query: string): DYNATRACE_ERROR_TYPE => {
	const queryType: string | undefined = Object.keys(errorMapping).find(
		(key: string) => query.includes(key)
	)
	return queryType ? errorMapping[queryType] : DYNATRACE_ERROR_TYPE.GENERIC
}

export const useCartFetcher = <TData, TVariables>({
	query,
	variables,
	headers = {},
	options,
}: {
	query: string
	variables?: TVariables
	headers?: Record<string, string>
	options?: SWRConfiguration
}): FetcherResponse<TData, TVariables> => {
	const { fetcher: fetcherGraphQL } = useGraphQL()
	const { token } = useSession()
	const { channel } = useMasterData()
	const { mutate: globalMutateFn } = useSWRConfig()
	const { handleError } = useHandleErrors()

	async function fetcher<FData, FVariables>(
		fetchData?: FetcherPropsType<FVariables>
	): Promise<FData> {
		const { url, fetcherOptions } = parseFetcherData(fetchData)
		const response = await fetcherGraphQL<FData, FVariables, Error>({
			url,
			query: fetcherOptions.query || query,
			variables: {
				...bagVariables,
				channel,
				...(fetcherOptions.variables || {}),
				...variables,
			} as FVariables,
			headers: {
				...bagHeaders,
				...(fetcherOptions.headers || {}),
				...headers,
			},
		})
		if (!response || isGraphQLResponseError(response)) {
			handleError(
				getErrorHandler(fetcherOptions.query || query),
				response as GraphQlError | GraphQlError[]
			)
			throw response
		}
		return response as FData
	}

	const { data, error, mutate, isLoading } = useSWR(
		token
			? [
					query,
					{
						...bagVariables,
						channel,
						...variables,
					},
				]
			: null,
		fetcher,
		options
	)

	function globalMutate(
		key: FetcherOptionsType<TVariables>,
		params: object,
		mutateOptions: object
	) {
		const globalVariables = {
			...bagVariables,
			channel,
		}
		return globalMutateFn(
			[
				key.query,
				{
					...globalVariables,
					...(key.variables || {}),
				},
			],
			params,
			mutateOptions
		)
	}

	return {
		data,
		error,
		fetcher,
		mutate,
		globalMutate,
		isLoading,
	} as FetcherResponse<TData, TVariables>
}
