export const enum BUTTON_VARIANT {
	DEFAULT = 'default',
	INVERSE = 'inverse',
}

export interface ButtonProps extends React.ComponentProps<'button'> {
	fullWidth?: boolean
	isLoading?: boolean
	variant?: BUTTON_VARIANT
	accessibilityText?: { loaded: string; loading: string }
}
