'use client'

import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { type ReactNode, useEffect, useState } from 'react'
import { cx } from 'utils/cx'

import { Loader } from '../../Loader'
import { BUTTON_VARIANT } from '../Button.types'

import styles from './ButtonContentWrapper.module.scss'

type ButtonContentWrapperProps = {
	children: ReactNode
	variant: BUTTON_VARIANT
	isLoading?: boolean
	accessibilityText?: { loaded: string; loading: string }
}

export const ButtonContentWrapper = (props: ButtonContentWrapperProps) => {
	const [showLoading, setShowLoading] = useState(false)
	const { t } = useLabels()

	const {
		children,
		isLoading,
		variant,
		accessibilityText = {
			loading: t('accessibility.loadingPage.text'),
			loaded: t('accessibility.uploadedPage.text'),
		},
	} = props

	useEffect(() => {
		if (!isLoading) {
			setShowLoading(false)
		}
	}, [isLoading])

	const showInverseLoading = variant === BUTTON_VARIANT.DEFAULT

	const handleAnimationEnd = () => {
		setShowLoading(true)
	}

	return isLoading && showLoading ? (
		<Loader
			accessibilityText={accessibilityText}
			inverse={showInverseLoading}
			loading
			className={styles.loader}
		/>
	) : (
		<span
			className={cx(styles.content, isLoading && styles.loadingText)}
			onAnimationEnd={handleAnimationEnd}
		>
			{children}
		</span>
	)
}
